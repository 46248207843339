import Image from "next/image";
import Link from "next/link";
import AnimationContainer from "~components/ui/animation-container";
import useBreakpoint from "~hooks/use-breakpoint";
import Button from "../../ui/button";
import Heading from "../../ui/heading";
import defaultData from "./data";

const ReferralBlock = ({ data = defaultData, locale, className }) => {
  const isMd = useBreakpoint("md");
  const { title, title_image, description, image, button, items, card_image } =
    data;

  return (
    <section className={`bg-gray-back pt-28 pb-32 ${className}`}>
      <div className="landing-container flex flex-col items-center gap-20 md:flex-row">
        <AnimationContainer
          className="relative flex flex-1 flex-col items-center overflow-hidden rounded-3xl bg-black p-5 text-white md:mb-0 md:flex-col md:p-10"
          delay={150}
        >
          <Image
            alt=""
            src={card_image}
            layout="fill"
            objectFit="cover"
            className="absolute h-full w-full"
          />
          <div className="z-[1]">
            <div className="mb-12 min-h-[256px]">
              <Heading
                variant="h2"
                image={title_image}
                locale={locale}
                className="mb-5 !text-3xl md:!text-4xl"
              >
                {title}
              </Heading>
              <p className="text-lg font-medium md:text-xl">{description}</p>
            </div>
            <div className="text-base md:text-lg">
              {items.map((item) => (
                <div
                  key={item.image}
                  className="mb-4 last:mb-0 flex flex-row rounded-2xl border border-white p-4 md:items-center"
                >
                  <div className="img-wrapper mr-4">
                    <Image
                      alt=""
                      src={item.image}
                      width={42}
                      height={42}
                      quality={100}
                      layout="fixed"
                      objectFit="contain"
                    />
                  </div>
                  <div className="leading-snug opacity-70">{item.text}</div>
                </div>
              ))}
            </div>
            <div className="flex w-full justify-center mt-6">
              <Link href="/home">
                <button
                  className={
                    "box-border whitespace-nowrap rounded-full px-5 py-2 font-semibold transition-all hover:scale-95 !bg-pink-base w-full md:w-auto !px-20 md:!py-3"
                  }
                >
                  {button}
                </button>
              </Link>
            </div>
          </div>
        </AnimationContainer>
        <AnimationContainer className="relative w-full flex-1" delay={300}>
          {isMd ? (
            <Image
              alt=""
              src={image.desktop}
              width={2}
              height={3}
              layout="responsive"
              objectFit="contain"
            />
          ) : (
            <Image
              alt=""
              src={image.mobile}
              width={2}
              height={3}
              layout="responsive"
              objectFit="contain"
            />
          )}
        </AnimationContainer>
      </div>
    </section>
  );
};

export default ReferralBlock;
